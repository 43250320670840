import { Component, OnInit } from '@angular/core';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-kurumsal-kimlik-tasarimi',
  templateUrl: './kurumsal-kimlik-tasarimi.component.html',
  styleUrls: ['./kurumsal-kimlik-tasarimi.component.scss']
})
export class KurumsalKimlikTasarimiComponent implements OnInit {

    public constructor(private titleService: Title) { }
    public setTitle (newTitle: string){
        this.titleService.setTitle( newTitle);
    }

    ngOnInit(): void {
        this.setTitle("Kurumsal Kimlik Tasarımı- Devex Medya");
    }

}
