<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Case Study</h3>
            <ul>
                <li><a href="index.html">Home</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Case Study</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="case-study-area pt-50 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="case-study-card">
                    <a routerLink="/case-study-details"><img src="assets/img/case-studies/case-studies1.png" alt="Images"></a>
                    <div class="content">
                        <h3><a routerLink="/case-study-details">Detection Project</a></h3>
                        <span>System Project</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="case-study-card">
                    <a routerLink="/case-study-details"><img src="assets/img/case-studies/case-studies2.png" alt="Images"></a>
                    <div class="content">
                        <h3><a routerLink="/case-study-details">Customer Segmentation</a></h3>
                        <span>Machine Learning</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="case-study-card">
                    <a routerLink="/case-study-details"><img src="assets/img/case-studies/case-studies3.png" alt="Images"></a>
                    <div class="content">
                        <h3><a routerLink="/case-study-details">Data Analysis</a></h3>
                        <span>Data Analysis</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="case-study-card">
                    <a routerLink="/case-study-details"><img src="assets/img/case-studies/case-studies4.png" alt="Images"></a>
                    <div class="content">
                        <h3><a routerLink="/case-study-details">Web Development</a></h3>
                        <span>Programming</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="case-study-card">
                    <a routerLink="/case-study-details"><img src="assets/img/case-studies/case-studies5.png" alt="Images"></a>
                    <div class="content">
                        <h3><a routerLink="/case-study-details">Artificial Intelligence</a></h3>
                        <span>Machine Learning</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="case-study-card">
                    <a routerLink="/case-study-details"><img src="assets/img/case-studies/case-studies6.png" alt="Images"></a>
                    <div class="content">
                        <h3><a routerLink="/case-study-details">Data Scientist</a></h3>
                        <span>Data Science</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="pagination-area text-center">
                    <a routerLink="/case-study" class="prev page-numbers"><i class="bx bx-chevron-left"></i></a>
                    <span class="page-numbers current" aria-current="page">1</span>
                    <a routerLink="/case-study" class="page-numbers">2</a>
                    <a routerLink="/case-study" class="page-numbers">3</a>
                    <a routerLink="/case-study" class="page-numbers">4</a>
                    <a routerLink="/case-study" class="next page-numbers"><i class="bx bx-chevron-right"></i></a>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="brand-logo-area pt-100">
    <div class="container-fluid">
        <div class="container-max">
            <div class="brand-logo-slider owl-carousel owl-theme">
                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo1.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo2.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo3.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo4.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo5.png" alt="Images">
                </div>

                <div class="brand-logo-item">
                    <img src="assets/img/brand/brand-logo6.png" alt="Images">
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer-style-two></app-footer-style-two>
