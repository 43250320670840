import { Component, OnInit } from '@angular/core';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-bilisim-danismanligi',
  templateUrl: './bilisim-danismanligi.component.html',
  styleUrls: ['./bilisim-danismanligi.component.scss']
})
export class BilisimDanismanligiComponent implements OnInit {

    public constructor(private titleService: Title) { }
    public setTitle (newTitle: string){
        this.titleService.setTitle( newTitle);
    }

    ngOnInit(): void {
        this.setTitle("Bilişim Danışmanlığı - Devex Medya");
    }

}
