import { Component, OnInit } from '@angular/core';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-domain-mail-hosting',
  templateUrl: './domain-mail-hosting.component.html',
  styleUrls: ['./domain-mail-hosting.component.scss']
})
export class DomainMailHostingComponent implements OnInit {

    public constructor(private titleService: Title) { }
    public setTitle (newTitle: string){
        this.titleService.setTitle( newTitle);
    }

    ngOnInit(): void {
        this.setTitle("Domain & Mail Hosting - Devex Medya");
    }

}
