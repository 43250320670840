import { Component, OnInit } from '@angular/core';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-adwords-google-reklam',
  templateUrl: './adwords-google-reklam.component.html',
  styleUrls: ['./adwords-google-reklam.component.scss']
})
export class AdwordsGoogleReklamComponent implements OnInit {

    public constructor(private titleService: Title) { }
    public setTitle (newTitle: string){
        this.titleService.setTitle( newTitle);
    }

    ngOnInit(): void {
        this.setTitle("Adwords Goggle Reklam - Devex Medya");
    }

}
