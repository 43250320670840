<app-navbar-style-one></app-navbar-style-one>

<div class="banner-area">
    <div class="container-fluid">
        <div class="container-max-2">
            <div class="col-lg-7 col-md-12">
                <div class="banner-content">
                    <div class="title">
                        <i class="flaticon-idea"></i>
                        <span>Hello World!</span>
                    </div>
                    <h1>Devex<b>Medya</b></h1>
                    <p>Dijital Dünyadaki Güvenilir Çözüm Ortağınız.Modern Dünyada Markanıza Değer Katalım.<br><strong>Başlamak İçin Hazır Mısınız?</strong></p>
                    <div class="banner-btn">
                        <a routerLink="/iletisim" class="get-btn">İletişim<i class='bx bx-plus'></i></a>
                        <a href="tel:+123-098-456-098" class="call-btn"><i class='bx bx-plus'></i>+90 530 946 25 00</a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="banner-shape-right"><img src="assets/img/home1/home-one-shape.png" alt="Images"></div>
    <div class="banner-img"><img src="assets/img/home1/home1.png" alt="Images"></div>
    <div class="banner-shape">
        <div class="shape1"><img src="assets/img/home1/home-one-shape3.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/home1/home-one-shape1.png" alt="Images"></div>
        <div class="shape3"><img src="assets/img/home1/home-one-shape2.png" alt="Images"></div>
        <div class="shape4"><img src="assets/img/home1/home-one-shape4.png" alt="Images"></div>
        <div class="shape5"><img src="assets/img/home1/home-one-shape5.png" alt="Images"></div>
    </div>
</div>


<div class="about-area pt-100 pb-70">
    <div class="container-fluid">
        <div class="container-max">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="about-img">
                        <img src="assets/img/about/about-img1.png" alt="Images">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="about-content">
                        <div class="section-title">
                            <span class="sp-after">Hakkımızda</span>
                            <h2 class="h2-color">Gelin Fikirlerinizi <b><br>Birlikte Hayata Geçirelim</b></h2>
                        </div>
                        <h3>Mowa Ajans ailesi olarak alanında uzman kadromuz ile fikirlerinize değer veriyor, kendi fikrimizcesine sahiplenerek bir yazılım harikasına dönüştürüyoruz.</h3>
                        <p>Mowa Ajans, sanal ve dijital dünyada müşterilerine tasarım ve yazılım alanında; web hizmetleri, yazılım hizmetleri,mobil uygulama ve dijital hizmetler sunarak bu bağlamda müşterilerinin yükselmelerini, markalaşmalarını sağlamayı ve verimliliklerini artırmayı hedeflemektedir.</p>
                        <div class="about-btn">
                            <a routerLink="/hakkimizda" class="default-btn">Daha Fazlası..<i class='bx bx-plus'></i></a>
                            <a href="https://www.youtube.com/watch?v=07d2dXHYb94" class="play-btn popup-btn"><i class="flaticon-play-button"></i><span> Tanıtım <br>Videomuz</span></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="about-shape">
        <div class="shape-1"><img src="assets/img/about/about-shape1.png" alt="Images"></div>
        <div class="shape-2"><img src="assets/img/shape/shape1.png" alt="Images"></div>
        <div class="shape-3"><img src="assets/img/shape/shape2.png" alt="Images"></div>
        <div class="shape-4"><img src="assets/img/shape/shape3.png" alt="Images"></div>
        <div class="shape-5"><img src="assets/img/shape/shape4.png" alt="Images"></div>
    </div>
</div>

<div class="counter-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-counter">
                    <i class="flaticon-confetti"></i>
                    <div class="content">
                        <h3>+ 10</h3>
                        <p>Yıllık Tecrübe</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-counter">
                    <i class="flaticon-project"></i>
                    <div class="content">
                        <h3>+ 100</h3>
                        <p>Tamamlanmış Proje</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-counter">
                    <i class="flaticon-customers"></i>
                    <div class="content">
                        <h3>+ 121</h3>
                        <p>Aktif Müşteri</p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-counter">
                    <i class="flaticon-financial-advisor"></i>
                    <div class="content">
                        <h3>+5</h3>
                        <p>Takım Arkadaşı</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="progress-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-after">Bizimle İlerleme Kaydedin</span>
            <h2 class="h2-color">Sitenizin Popülerliğini <b>Artırmak İstemez Misiniz?</b></h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-sm-6">
                <div class="progress-card pr-bg-color-1">
                    <img src="assets/img/progress-icon/progress-icon1.png" alt="Images">
                    <h3>28,998</h3>
                        <p>Tane Anahtar Kelime</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="progress-card pr-bg-color-2">
                    <img src="assets/img/progress-icon/progress-icon2.png" alt="Images">
                    <h3>%600</h3>
                    <p>Organik Görüntülenme Artışı</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 offset-sm-3  offset-lg-0">
                <div class="progress-card pr-bg-color-3">
                    <img src="assets/img/progress-icon/progress-icon3.png" alt="Images">
                    <h3>%100</h3>
                    <p>Site Hızı Artışı</p>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="seo-area">
    <div class="container-fluid">
        <div class="seo-width">
            <div class="row align-items-center">
                <div class="col-lg-8 col-md-12">
                    <div class="seo-form">
                        <h2>Sitenizin Seo Skorunu Merak Etmiyor Musunuz?</h2>

                        <div class="seo-form-area">
                            <form class="seo-form-option">
                                <div class="row">
                                    <div class="col-lg-4 col-sm-6 col-md-4">
                                        <div class="form-group">
                                            <i class='flaticon-place'></i>
                                            <input class="form-control form-border" type="text" placeholder="Websitenizin URL'si">
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-sm-6 col-md-4">
                                        <div class="form-group">
                                            <i class='flaticon-vision'></i>
                                            <input class="form-control" type="email"  placeholder="Mail Adresiniz">
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-sm-6 col-md-4 offset-sm-3 offset-md-0 offset-lg-0">
                                        <button type="submit" class="default-btn"><a href="iletisim">
                                            Optimize Et</a>
                                            <i class='bx bx-plus'></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-12">
                    <div class="seo-img">
                        <img src="assets/img/seo-rank.png" alt="Images">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="services-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sp-after">Servislerimiz</span>
            <h2 class="h2-color">Size En İyi Hizmeti Sağlamak İçin Uğraşıyoruz. </h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-12">
                <div class="services-card-into">
                    <div class="services-card">
                        <a routerLink="/mobil-programlama"><img src="assets/img/service/service-icon1.png" alt="Images"></a>
                        <h3><a routerLink="/mobil-programlama">Mobil Programlama</a></h3>
                        <p>Mowa Ajans, IOS ve Android işletim sistemine sahip telefon, tablet ve diğer mobil cihazlarda çalışan native ve hybrid mobil uygulamaları geliştirmektedir. Son teknolojiyi sizin için kullanıyoruz.</p>
                        <div class="services-card-shape">
                            <img src="assets/img/service/service-shape.png" alt="Images">
                        </div>
                    </div>
                    <div class="services-dots"><img src="assets/img/service/dots.png" alt="Images"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="services-card">
                    <a routerLink="/bilisim-danismanligi"><img src="assets/img/service/service-icon2.png" alt="Images"></a>
                    <h3><a routerLink="/bilisim-danismanligi">Sosyal Medya</a></h3>
                    <p>Instagram, Twitter, Facebook ve sektörünüz için gerekli diğer tüm sosyal medya platformlarında kurum ve işletmenize profesyonel yönetim hizmetleri sunuyoruz. </p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>

                <div class="services-card">
                    <a routerLink="/adwords-google-reklam"><img src="assets/img/service/service-icon3.png" alt="Images"></a>
                    <h3><a routerLink="/adwords-google-reklam">Adwords Google Reklam</a></h3>
                    <p>Tekirdağ'da yaşayan kullanıcıların saat 13:00 - 15:00 saatleri arasında "yazılım ve tasarım firması" diye internetten arama yaptıklarında ilk sıralarda sizi gördüklerini düşünün. Böyle detaylı özelleştirilebilir bir sistem sizce de mükemmel değil mi? </p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="services-card">
                    <a routerLink="/seo-optimizasyonu"><img src="assets/img/service/service-icon4.png" alt="Images"></a>
                    <h3><a routerLink="/seo-optimizasyonu">SEO Optimizasyonu</a></h3>
                    <p>Uluslararası çözüm ortaklarımız ve üst düzey tecrübelerimiz ile markalar için dijital pazarlama hizmetleri ve seo (arama motoru optimizasyonu) çözümleri </p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>

                <div class="services-card">
                    <a routerLink="/web-programlama"><img src="assets/img/service/service-icon5.png" alt="Images"></a>
                    <h3><a routerLink="/web-programlama">Web Programlama</a></h3>
                    <p>Yazılım çözümleri firmaların olduka büyük ölçüde işini kolaylaştırmakta ve iş tasarrufu sağlamaktadır.</p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>

                <div class="services-card">
                    <a routerLink="/e-ticaret"><img src="assets/img/service/service-icon6.png" alt="Images"></a>
                    <h3><a routerLink="/e-ticaret">E-Ticaret</a></h3>
                    <p>Mowa Ajans E-Ticaret sistemleri ile, ürünlerinizi internet ortamında ulaşabileceğiniz maksimum kişiye ulaştırabilir, kazancınızı daha fazla artırabilirsiniz.</p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div>
        </div>
    </div>

    <div class="services-shape">
        <div class="services-bg"><img src="assets/img/service/service-bg.png" alt="Images"></div>
        <div class="shape1"><img src="assets/img/shape/shape4.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/shape/shape2.png" alt="Images"></div>
    </div>
</div>

<div class="work-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-after">Neden mi En İyiyiz?</span>
            <h2 class="h2-color">Mowa Ajans Olarak <b>Gurur Duyuyoruz</b></h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-sm-6">
                <div class="work-card">
                    <h2>+</h2>
                    <h3>Takım Arkadaşlarımız</h3>
                    <p>Birbiriyle uyumlu ve sektörün en iyisi olan takım arkadaşlarımız </p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="work-card active">
                    <h2>+</h2>
                    <h3>Plan ve Program</h3>
                    <p>Bir işe başlamadan önce yaptığımız plan ve programlar </p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                <div class="work-card">
                    <h2>=</h2>
                    <h3>Sektörün En İyisi</h3>
                    <p> Ekip içi Uyum Ve İşlerde %100 Başarı </p>
                </div>
            </div>
        </div>
    </div>

    <div class="work-shape"><img src="assets/img/shape/work-shape.png" alt="Images"></div>
</div>


<div class="team-area pt-100 pb-70">
    <div class="container-fluid p-0 m-0">
        <div class="section-title text-center">
            <span class="sp-after">Takım Arkadaşlarımız</span>
            <h2 class="h2-color">Ekibmizle  <b>Tanışın</b></h2>
            <p>Harika fikirler, alanında uzman kişilerle hayat bulur. </p>
        </div>

        <div class="team-slider owl-carousel owl-theme pt-45">
            <div class="team-item">
                <a routerLink="/takim-arkadaslarimiz" class="team-img team-bg-before"><img src="assets/img/team/team-img1.jpg" alt="Images"></a>
                <div class="team-content">
                    <h3><a routerLink="/takim-arkadaslarimiz">Mehmet YURDAKUL</a></h3>
                    <span>Back-End Developer</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="team-item">
                <a routerLink="/takim-arkadaslarimiz" class="team-img team-bg-before-2"><img src="assets/img/team/team-img2.jpg" alt="Images"></a>
                <div class="team-content">
                    <h3><a routerLink="/takim-arkadaslarimiz">Veysel EROL</a></h3>
                    <span>Creative Director</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="team-item">
                <a routerLink="/takim-arkadaslarimiz" class="team-img team-bg-before-3"><img src="assets/img/team/team-img3.jpg" alt="Images"></a>
                <div class="team-content">
                    <h3><a routerLink="/takim-arkadaslarimiz">Sevdanur Çakır</a></h3>
                    <span>Web Designer</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="team-item">
                <a routerLink="/takim-arkadaslarimiz" class="team-img team-bg-before"><img src="assets/img/team/team-img4.jpg" alt="Images"></a>
                <div class="team-content">
                    <h3><a routerLink="/takim-arkadaslarimiz">Doğukan KELEŞ</a></h3>
                    <span>CEO Specialist</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="team-item">
                <a routerLink="/takim-arkadaslarimiz" class="team-img team-bg-before-2"><img src="assets/img/team/team-img5.jpg" alt="Images"></a>
                <div class="team-content">
                    <h3><a routerLink="/takim-arkadaslarimiz">Emir POLAT</a></h3>
                    <span>Graphic Designer</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="team-shape">
        <div class="team-shape1"><img src="assets/img/shape/team-shape.png" alt="Images"></div>
        <div class="team-shape2"><img src="assets/img/shape/shape7.png" alt="Images"></div>
        <div class="team-shape3"><img src="assets/img/shape/shape6.png" alt="Images"></div>
        <div class="team-shape4"><img src="assets/img/shape/shape2.png" alt="Images"></div>
    </div>
</div>

<app-footer-style-one></app-footer-style-one>
