<app-navbar-style-two></app-navbar-style-two>

<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Referanslarımız</h3>
            <ul>
                <li><a href="https://www.devexmedya.com">Anasayfa</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Referanslarımız</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="case-study-area pt-50 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="case-study-card">
                    <a href="https://www.isikkarot.com/" target="_blank"><img src="assets/img/case-studies/isik-karot.JPG" alt="Images"></a>
                    <div class="content">
                        <h3><a href="https://www.isikkarot.com/" target="_blank">Işık Karot </a></h3>
                        <span>Web Tasarım</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="case-study-card">
                    <a href="https://afcfastfood.com/" target="_blank"><img src="assets/img/case-studies/afc.JPG" alt="Images"></a>
                    <div class="content">
                        <h3><a href="https://afcfastfood.com/" target="_blank">AFC Fast Food</a></h3>
                        <span>Web Tasarım</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="case-study-card">
                    <a href="http://www.3alp.net/" target="_blank"><img src="assets/img/case-studies/3alp.JPG" alt="Images"></a>
                    <div class="content">
                        <h3><a href="http://www.3alp.net/" target="_blank">3ALP</a></h3>
                        <span>Web Tasarım</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="case-study-card">
                    <a href="https://www.mowaproduction.com/" target="_blank"><img src="assets/img/case-studies/mowa.JPG" alt="Images"></a>
                    <div class="content">
                        <h3><a href="https://www.mowaproduction.com/" target="_blank">Mowa Production</a></h3>
                        <span>Web Tasarım</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="case-study-card">
                    <a href="http://karmavent.com.tr/" target="_blank"><img src="assets/img/case-studies/karma.JPG" alt="Images"></a>
                    <div class="content">
                        <h3><a href="http://karmavent.com.tr/" target="_blank">Karmavent</a></h3>
                        <span>Web Tasarım</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="case-study-card">
                    <a href="https://airhavalandirma.com/" target="_blank"><img src="assets/img/case-studies/air.JPG" alt="Images"></a>
                    <div class="content">
                        <h3><a href="https://airhavalandirma.com/" target="_blank">Air Havalandırma</a></h3>
                        <span>Web Tasarım</span>
                    </div>
                </div>
            </div>



            <div class="col-lg-4 col-md-6">
                <div class="case-study-card">
                    <a href="https://karotcu.ist/" target="_blank"><img src="assets/img/case-studies/karotcuist.JPG" alt="Images"></a>
                    <div class="content">
                        <h3><a href="https://karotcu.ist/"target="_blank">Karotçu İST</a></h3>
                        <span>Web Tasarım</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="case-study-card">
                    <a href="https://tolgatuz.com.tr/" target="_blank"><img src="assets/img/case-studies/tolgatuz.JPG" alt="Images"></a>
                    <div class="content">
                        <h3><a href="https://tolgatuz.com.tr/" target="_blank">Tolga Tuz Sanayi</a></h3>
                        <span>Web Tasarım</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="case-study-card">
                    <a href="https://gultekmekanik.com/" target="_blank"><img src="assets/img/case-studies/gultek.JPG" alt="Images"></a>
                    <div class="content">
                        <h3><a href="https://gultekmekanik.com/" target="_blank">Gültek Mekanik</a></h3>
                        <span>Web Tasarım</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="case-study-card">
                    <a href="https://enuygunfirma.com/" target="_blank"><img src="assets/img/case-studies/enuygun.JPG" alt="Images"></a>
                    <div class="content">
                        <h3><a href="https://enuygunfirma.com/">En Uygun Firma</a></h3>
                        <span>Web Tasarım</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="case-study-card">
                    <a href="https://mimarcastudio.com/" target="_blank"><img src="assets/img/case-studies/mimarca.JPG" alt="Images"></a>
                    <div class="content">
                        <h3><a href="https://mimarcastudio.com/" target="_blank">Mimarca Mimarlık Ofisi</a></h3>
                        <span>Web Tasarım</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="case-study-card">
                    <a href="https://orsandesen.com.tr/" target="_blank"><img src="assets/img/case-studies/orsan.JPG" alt="Images"></a>
                    <div class="content">
                        <h3><a href="https://orsandesen.com.tr/" target="_blank">Örsan Desen</a></h3>
                        <span>Web Tasarım</span>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="case-study-card">
                    <a href="https://teknikguvenliksistemleri.com/" target="_blank"><img src="assets/img/case-studies/teknik.JPG" alt="Images"></a>
                    <div class="content">
                        <h3><a href="https://teknikguvenliksistemleri.com/" target="_blank"> Teknik Güvenlik Sistemleri</a></h3>
                        <span>Web Tasarım</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="case-study-card">
                    <a href="https://www.yurdakulaluminyum.com.tr/" target="_blank"><img src="assets/img/case-studies/alu.JPG" alt="Images"></a>
                    <div class="content">
                        <h3><a href="https://www.yurdakulaluminyum.com.tr/" target="_blank"> Teknik Güvenlik Sistemleri</a></h3>
                        <span>Web Tasarım</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="case-study-card">
                    <a href="https://www.karotuzmani.com/" target="_blank"><img src="assets/img/case-studies/uzman.JPG" alt="Images"></a>
                    <div class="content">
                        <h3><a href="https://www.karotuzmani.com/" target="_blank"> Karot Uzmani </a></h3>
                        <span>Web Tasarım</span>
                    </div>
                </div>
            </div>



        </div>
    </div>
</div>


<app-footer-style-one></app-footer-style-one>
