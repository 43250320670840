import { Component, OnInit } from '@angular/core';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-tanitim-filmi',
  templateUrl: './tanitim-filmi.component.html',
  styleUrls: ['./tanitim-filmi.component.scss']
})
export class TanitimFilmiComponent implements OnInit {

    public constructor(private titleService: Title) { }
    public setTitle (newTitle: string){
        this.titleService.setTitle( newTitle);
    }

    ngOnInit(): void {
        this.setTitle("Tanıtım Filmi - Devex Medya");
    }

}
