import { Component, OnInit } from '@angular/core';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-cozum-ortaklarimiz',
  templateUrl: './cozum-ortaklarimiz.component.html',
  styleUrls: ['./cozum-ortaklarimiz.component.scss']
})
export class CozumOrtaklarimizComponent implements OnInit {

    public constructor(private titleService: Title) { }
    public setTitle (newTitle: string){
        this.titleService.setTitle( newTitle);
    }

    ngOnInit(): void {
        this.setTitle("Çözüm Ortaklarımız - Devex Medya");
    }

}
