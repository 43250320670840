import { Component, OnInit } from '@angular/core';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-baski-hizmetleri',
  templateUrl: './baski-hizmetleri.component.html',
  styleUrls: ['./baski-hizmetleri.component.scss']
})
export class BaskiHizmetleriComponent implements OnInit {

    public constructor(private titleService: Title) { }
    public setTitle (newTitle: string){
        this.titleService.setTitle( newTitle);
    }

    ngOnInit(): void {
        this.setTitle("Baskı Hizmetleri - Devex Medya");
    }

}
