<app-navbar-style-two></app-navbar-style-two>

<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Hizmetlerimiz</h3>
            <ul>
                <li><a href="https://www.devexmedya.com" >Anasayfa</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Hizmetlerimiz</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="services-widget-area pt-50 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Hizmetlerimiz</span>
            <h2 class="h2-color">Geniş <b>Çözüm Alanlarımız</b></h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a routerLink="/mobil-programlama"><img src="assets/img/service/service-icon1.png" alt="Images"></a>
                    <h3><a routerLink="/mobil-programlama">Mobil Programlama</a></h3>
                    <p>Farklı proje ve ihtiyaçlarınıza yönelik özel yazılımlar ile firmanıza ve size özel mobil uygulamalar geliştiriyoruz. Geliştirdiğimiz mobil uygulamalar ve stratejilerle pazarlamadaki gücünüzün ve sunmuş olduğunuz hizmetin kalitesinin artmasını sağlamaktayız.</p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a routerLink="/bilisim-danismanligi"><img src="assets/img/service/service-icon2.png" alt="Images"></a>
                    <h3><a routerLink="/bilisim-danismanligi">Bilişim Danışmanlığı</a></h3>
                    <p>
                        Web sitesi tasarımı, google ve diğer arama motorları araçları ile ürün ve hizmetlerinizin tanıtımı, firmanızın iş süreçlerini web üzerinden takip edebileceğiniz yazılımların yönetimi konularında bilişim danışmanlığı hizmeti veriyoruz.</p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a routerLink="/adwords-google-reklam"><img src="assets/img/service/service-icon3.png" alt="Images"></a>
                    <h3><a routerLink="/adwords-google-reklam">Adwords Google Reklam</a></h3>
                    <p>İnternet dünyasının en yaygın kullanılan siteleri; arama motorları olarak biliniyor. Yoğun kullanımı dikkate alındığında arama motorları; en verimli reklam alanları olarak göze çarpıyor. Google adwords danışmanlığı ile siteler reklamlarını daha geniş kitlelere ulaştırabiliyor. </p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a routerLink="/seo-optimizasyonu"><img src="assets/img/service/service-icon4.png" alt="Images"></a>
                    <h3><a routerLink="/seo-optimizasyonu">SEO Optimizasyonu</a></h3>
                    <p>Arama motoru optimizasyonu, web sitelerini arama motorlarının daha rahat bir şekilde anlayabilmesine "taramasına" olanak sağlayacak şekilde arama motorlarının kriterlerine..</p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a routerLink="/web-programlama"><img src="assets/img/service/service-icon5.png" alt="Images"></a>
                    <h3><a routerLink="/web-programlama">Web Programlama</a></h3>
                    <p>Modern, işlevsel, mobil uyumlu, özgün, kullanıcı dostu, ekonomik, yenilikçi, profesyonel, stratejik, yükek geri dönüş ve başarı odaklı web tasarım projeleri üretiyoruz..</p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a routerLink="/e-ticaret"><img src="assets/img/service/service-icon6.png" alt="Images"></a>
                    <h3><a routerLink="/e-ticaret">E-Ticaret</a></h3>
                    <p>Türkiye ve Dünya’da her geçen gün yeni bir eticaret sitesi açılırken bir diğeri de kapanıyor. Yayınlanan eticaret raporlarında çarpıcı ve şaşırtıcı sonuçlar bulunabiliyor.</p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a routerLink="/web-tasarim"><img src="assets/img/service/service-icon10.png" alt="Images"></a>
                    <h3><a routerLink="/web-tasarim">Web Tasarım</a></h3>
                    <p>Devex Medya, benzersiz, seçkin, amaca uygun, güncel web tasarım trendlerine uygun işler yapmaktadır. Bu prensip, Devex Medya web tasarım kültürünün temelidir.</p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a routerLink="/yazilim-gelistirme"><img src="assets/img/service/service-icon9.png" alt="Images"></a>
                    <h3><a routerLink="/yazilim-gelistirme">Yazılım Geliştirme</a></h3>
                    <p>Windows uygulamalarının geliştirilmesi günümüzde pek çok farklı platformda kullanılmakta olan Windows uygulamalarının daha yaygın şekilde kullanılabilmesi için önemlidir.   </p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="services-card">
                    <a routerLink="/e-ticaret"><img src="assets/img/service/service-icon6.png" alt="Images"></a>
                    <h3><a routerLink="/mail-domain">Domain & Mail Hosting</a></h3>
                    <p>Türkiye ve Dünya’da her geçen gün yeni bir eticaret sitesi açılırken bir diğeri de kapanıyor. Yayınlanan eticaret raporlarında çarpıcı ve şaşırtıcı sonuçlar bulunabiliyor.</p>
                    <div class="services-card-shape"><img src="assets/img/service/service-shape.png" alt="Images"></div>
                </div>
            </div>

        </div>
    </div>
</div>


<app-footer-style-one></app-footer-style-one>
