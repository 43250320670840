import { Component, OnInit } from '@angular/core';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-hizmetlerimiz',
  templateUrl: './hizmetlerimiz.component.html',
  styleUrls: ['./hizmetlerimiz.component.scss']
})
export class HizmetlerimizComponent implements OnInit {

    public constructor(private titleService: Title) { }
    public setTitle (newTitle: string){
        this.titleService.setTitle( newTitle);
    }

    ngOnInit(): void {
        this.setTitle("Hizmetlerimiz - Devex Medya");
    }

}
