<app-navbar-style-two></app-navbar-style-two>

<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Yazılım Geliştirme</h3>
            <ul>
                <li><a href="https://www.devexmedya.com">Anasayfa</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Yazılım Geliştirme</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="services-details-area pt-50 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="services-left">
                    <div class="services-content">
                        <img src="assets/img/service/yazilim-gelistirme.jpg" alt="Images">
                        <h2>Yazılım Geliştirme</h2>
                        <p>Windows uygulamalarının geliştirilmesi günümüzde pek çok farklı platformda kullanılmakta olan Windows uygulamalarının daha yaygın şekilde kullanılabilmesi için önemlidir. Devex Medya olarak Windows uygulamaları arasında yer alan Windows Phone Application başta olmak üzere diğer tüm platformlarda uygulamalarınızı geliştirmeniz için sizlere profesyonel hizmet sunuyoruz.</p>
                    </div>

                    <div class="row">
                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <h3>Web Uygulama Geliştirme</h3>
                                <p>Farklı sektörlerde faaliyet göstermekte olan şirketlere, iş stratejileri doğrultusunda, yatırımlarını gelire dönüştürecek özel uygulamalar geliştirmekteyiz. Müşterilerimizin farklı sektörlerden olması ve her müşterimizin ihtiyaçlarının farklı yönde olmasından dolayı, müşterilerimiz ile doğrudan yakın temas kurarak öncelikle web uygulama geliştirme konusunda gerekli stratejileri belirleyerek çalışmalarımıza başlıyoruz.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <h3>Sitecore Geliştirme</h3>
                                <p>Sitecore içerik yönetimi, yenilikçi ve esnek teknolojisi ile dijital imajınızı kolay bir şekilde yaratmanızı ve yönetmenizi sağlıyor. Entegre biçimdeki dijital pazarlama araçları, detaylı şekilde kişiselleştirilebilme özelliği, mobil istemci desteği ve yüzlerce modülü ile orta ve yüksek ölçekli şirketlerin ilk tercih ettiği platform arasında yer alıyor.

                                </p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <h3>Windows Uygulama Geliştirme</h3>
                                <p>Windows uygulamalarının geliştirilmesi günümüzde pek çok farklı platformda kullanılmakta olan Windows uygulamalarının daha yaygın şekilde kullanılabilmesi için önemlidir. Devex Medya olarak Windows uygulamaları arasında yer alan Windows Phone Application başta olmak üzere diğer tüm platformlarda uygulamalarınızı geliştirmeniz için sizlere profesyonel hizmet sunuyoruz.

                                </p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <h3>Mobil Uygulama Geliştirme</h3>
                                <p>İnsanlar artık teknoloji çağından beklenti olarak iki ayrı konuya ihtiyaç duyuyorlar bunlar; kolaylık ve hız. Firmaların, ürün ve hizmetlerini kolay yoldan ulaştırabilmeleri ise en temel ihtiyaçlar arasında yer alıyor. Bu sebeple mobil cihazlar günümüzde giderek yükselmeye devam ediyor. Masaüstü cihazları geçecek sayıda kullanıcıya ulaşmaları ve internete istenilen her yerden erişilebilmesi ise mobil cihazların tercih edilmesindeki en temel sebepler arasında yer alıyor.

                                </p>

                            </div>
                        </div>
                    </div>

                    <div class="content-widget-area">
                        <div class="row">
                            <div class="col-lg-7 col-md-12">
                                <div class="content-widget-img">
                                    <img src="assets/img/service/service-details-img2.png" alt="Images">
                                </div>
                            </div>

                            <div class="col-lg-5 col-md-12">
                                <div class="content-widget-text">
                                    <h2>Neden Bizi Seçmelisiniz?</h2>
                                    <p></p>
                                    <ul>
                                        <li><i class='bx bx-check'></i> Özgün Tasarım</li>
                                        <li><i class='bx bx-check'></i> Tam Mobil Uyum  </li>
                                        <li><i class='bx bx-check'></i> Temiz Kodlama </li>

                                        <li><i class='bx bx-check'></i> Web Standartları </li>
                                        <li><i class='bx bx-check'></i> Seo Etkisi </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <p>Web yazılım konusunda uzman ekibimiz sizin istekleriniz doğrultusunda size özel bir web yazılım çözümü sunmaktadır. Baştan sona oldukça kullanılabilir ve yüksek teknoloji kullanılmış bu ürünlerimizi denemek isterseniz lütfen bizim ile iletişime geçin.</p>
                </div>
            </div>

            <app-servilersag></app-servilersag>
        </div>
    </div>
</div>


<app-footer-style-one></app-footer-style-one>
