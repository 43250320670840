<div class="col-lg-12 col-md-12">
    <div class="services-widget-right">
        <div class="widget-category">
            <h3>Hizmetlerimiz</h3>

            <ul>
                <li><a routerLink="/web-tasarim">Web Tasarım</a></li>
                <li><a routerLink="/web-programlama">Web Programlama</a></li>
                <li><a routerLink="/e-ticaret">E Ticaret</a></li>
                <li><a routerLink="/mobil-programlama">Mobil Programlama</a></li>
                <li><a routerLink="/yazilim-gelistirme">Yazılım Geliştirme</a></li>
                <li><a routerLink="/seo-optimizasyonu">SEO Optimizasyonu</a></li>
                <li><a routerLink="/adwords-google-reklam">Adwords Google Reklam</a></li>
                <li><a routerLink="/domain-mail-hosting">Domain & Mail Hosting</a></li>
                <li><a routerLink="/bilisim-danismanligi">Bilişim Danışmanlığı</a></li>
                <li><a routerLink="/son-calismalarimiz">Son Çalışmalarımız</a></li>
            </ul>
        </div>

        <div class="contact-widget">
            <h2>İletişim</h2>

            <ul>
                <li>
                    <i class="flaticon-telephone"></i>
                    <div class="content">
                        <h3>Telefon: </h3>
                        <span><a href="tel:+902827262538">(+90)282 726 25 38</a></span>
                    </div>
                </li>
                <li>
                    <i class="flaticon-email-1"></i>
                    <div class="content">
                        <h3>Mail:</h3>
                        <span><a href="mailto:info@devexmedya.com">info@devexmedya.com</a></span>
                    </div>
                </li>
                <li>
                    <i class="flaticon-planet-earth"></i>
                    <div class="content">
                        <h3>Adres:</h3>
                        <span>Gabralı İş Merkezi<br>Çerkezköy/Tekirdağ</span>
                    </div>
                </li>
            </ul>
        </div>

    </div>
</div>
