<app-navbar-style-one></app-navbar-style-one>

<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Çözüm Ortaklarımız</h3>
            <ul>
                <li><a href="https://www.devexmedya.com">Anasayfa</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Çözüm Ortaklarımız</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="case-study-area pt-50 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="case-study-card">
                    <a href="https://godaddy.com"><img src="assets/img/case-studies/godaddy.png" alt="Images"></a>

                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="case-study-card">
                    <a href="https://www.natro.com/"><img src="assets/img/case-studies/natro.png" alt="Images"></a>

                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="case-study-card">
                    <a href="https://ads.google.com/"><img src="assets/img/case-studies/googleads.png" alt="Images"></a>

                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="case-study-card">
                    <a href="https://analytics.google.com/"><img src="assets/img/case-studies/analyt.png" alt="Images"></a>

                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="case-study-card">
                    <a href="https://search.google.com/search-console/about"><img src="assets/img/case-studies/google-search-console-nedir.png" alt="Images"></a>

                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="case-study-card">
                    <a href="https://marketingplatform.google.com/about/tag-manager/"><img src="assets/img/case-studies/google-tag-manager-gtm-nedir.jpg" alt="Images"></a>

                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="case-study-card">
                    <a href="https://wordpress.com/tr/"><img src="assets/img/case-studies/wordpres.jpg" alt="Images"></a>

                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="case-study-card">
                    <a href="https://www.semrush.com/"><img src="assets/img/case-studies/semrush-lockup-vertical-rgb.png" alt="Images"></a>

                </div>
            </div>




        </div>
    </div>
</div>


<app-footer-style-one></app-footer-style-one>
