import { Component, OnInit } from '@angular/core';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-e-ticaret',
  templateUrl: './e-ticaret.component.html',
  styleUrls: ['./e-ticaret.component.scss']
})
export class ETicaretComponent implements OnInit {

    public constructor(private titleService: Title) { }
    public setTitle (newTitle: string){
        this.titleService.setTitle( newTitle);
    }

    ngOnInit(): void {
        this.setTitle("E-Ticaret - Devex Medya");
    }

}
