import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SeoAgencyComponent } from './components/pages/seo-agency/seo-agency.component';
import { AiStartupComponent } from './components/pages/ai-startup/ai-startup.component';
import { MachineLearningComponent } from './components/pages/machine-learning/machine-learning.component';
import { AboutComponent } from './components/pages/about/about.component';
import { TeamComponent } from './components/pages/team/team.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { TestimonialsComponent } from './components/pages/testimonials/testimonials.component';
import { CaseStudyComponent } from './components/pages/case-study/case-study.component';
import { CaseStudyDetailsComponent } from './components/pages/case-study-details/case-study-details.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { SignInComponent } from './components/pages/sign-in/sign-in.component';
import { SignUpComponent } from './components/pages/sign-up/sign-up.component';
import { TermsConditionsComponent } from './components/pages/terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { ServicesComponent } from './components/pages/services/services.component';
import { ServicesDetailsComponent } from './components/pages/services-details/services-details.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { IletisimComponent } from './components/pages/iletisim/iletisim.component';
import {WebTasarimComponent} from './components/pages/web-tasarim/web-tasarim.component';
import {WebProgramlamaComponent} from './components/pages/web-programlama/web-programlama.component';
import {ETicaretComponent} from './components/pages/e-ticaret/e-ticaret.component';
import {MobilProgramlamaComponent} from './components/pages/mobil-programlama/mobil-programlama.component';
import {YazilimGelistirmeComponent} from './components/pages/yazilim-gelistirme/yazilim-gelistirme.component';
import {SeoOptimizasyonuComponent} from './components/pages/seo-optimizasyonu/seo-optimizasyonu.component';
import {AdwordsGoogleReklamComponent} from './components/pages/adwords-google-reklam/adwords-google-reklam.component';
import {DomainMailHostingComponent} from './components/pages/domain-mail-hosting/domain-mail-hosting.component';
import {BilisimDanismanligiComponent} from './components/pages/bilisim-danismanligi/bilisim-danismanligi.component';
import {KalitePolitikamizComponent} from './components/pages/kalite-politikamiz/kalite-politikamiz.component';
import {CozumOrtaklarimizComponent} from './components/pages/cozum-ortaklarimiz/cozum-ortaklarimiz.component';
import {HakkimizdaComponent} from './components/pages/hakkimizda/hakkimizda.component';
import {HizmetlerimizComponent} from './components/pages/hizmetlerimiz/hizmetlerimiz.component';
import {LogoTasarimComponent} from './components/pages/logo-tasarim/logo-tasarim.component';
import {KurumsalKimlikTasarimiComponent} from './components/pages/kurumsal-kimlik-tasarimi/kurumsal-kimlik-tasarimi.component';
import {KatalogBrosurTasarimiComponent} from './components/pages/katalog-brosur-tasarimi/katalog-brosur-tasarimi.component';
import {MarkaTesciliComponent} from './components/pages/marka-tescili/marka-tescili.component';
import {BaskiHizmetleriComponent} from './components/pages/baski-hizmetleri/baski-hizmetleri.component';
import {TanitimFilmiComponent} from './components/pages/tanitim-filmi/tanitim-filmi.component';
import {ReferanslarimizComponent} from './components/pages/referanslarimiz/referanslarimiz.component';
import {TakimArkadaslarimizComponent} from './components/pages/takim-arkadaslarimiz/takim-arkadaslarimiz.component';


const routes: Routes = [
    {path: '', component: SeoAgencyComponent},
    {path: 'ai-startup', component: AiStartupComponent},
    {path: 'machine-learning', component: MachineLearningComponent},
    {path: 'about', component: AboutComponent},
    {path: 'team', component: TeamComponent},
    {path: 'pricing', component: PricingComponent},
    {path: 'faq', component: FaqComponent},
    {path: 'testimonials', component: TestimonialsComponent},
    {path: 'case-study', component: CaseStudyComponent},
    {path: 'case-study-details', component: CaseStudyDetailsComponent},
    {path: 'error', component: ErrorComponent},
    {path: 'sign-in', component: SignInComponent},
    {path: 'sign-up', component: SignUpComponent},
    {path: 'terms-conditions', component: TermsConditionsComponent},
    {path: 'privacy-policy', component: PrivacyPolicyComponent},
    {path: 'coming-soon', component: ComingSoonComponent},
    {path: 'services', component: ServicesComponent},
    {path: 'services-details', component: ServicesDetailsComponent},
    {path: 'blog', component: BlogComponent},
    {path: 'blog-details', component: BlogDetailsComponent},
    {path: 'iletisim', component: IletisimComponent},
    {path: 'web-tasarim',component: WebTasarimComponent},
    {path: 'web-programlama',component: WebProgramlamaComponent},
    {path: 'e-ticaret',component: ETicaretComponent},
    {path: 'mobil-programlama',component: MobilProgramlamaComponent},
    {path: 'yazilim-gelistirme',component: YazilimGelistirmeComponent},
    {path: 'seo-optimizasyonu',component: SeoOptimizasyonuComponent},
    {path: 'adwords-google-reklam',component: AdwordsGoogleReklamComponent},
    {path: 'domain-mail-hosting',component: DomainMailHostingComponent},
    {path: 'bilisim-danismanligi',component: BilisimDanismanligiComponent},
    {path: 'kalite-politikamiz',component: KalitePolitikamizComponent},
    {path: 'kalite-politikamiz',component: KalitePolitikamizComponent},
    {path: 'cozum-ortaklarimiz',component: CozumOrtaklarimizComponent},
    {path: 'hakkimizda',component: HakkimizdaComponent},
    {path: 'hizmetlerimiz',component: HizmetlerimizComponent},
    {path: 'logo-tasarim',component: LogoTasarimComponent},
    {path: 'kurumsal-kimlik-tasarimi',component: KurumsalKimlikTasarimiComponent},
    {path: 'katalog-brosur-tasarimi',component: KatalogBrosurTasarimiComponent},
    {path: 'marka-tescili',component: MarkaTesciliComponent},
    {path: 'baski-hizmetleri',component: BaskiHizmetleriComponent},
    {path: 'tanitim-filmi',component: TanitimFilmiComponent},
    {path: 'referanslarimiz',component: ReferanslarimizComponent},
    {path: 'takim-arkadaslarimiz',component: TakimArkadaslarimizComponent},





    {path: '**', component: ErrorComponent}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
