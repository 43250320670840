import { Component, OnInit } from '@angular/core';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-yazilim-gelistirme',
  templateUrl: './yazilim-gelistirme.component.html',
  styleUrls: ['./yazilim-gelistirme.component.scss']
})
export class YazilimGelistirmeComponent implements OnInit {

    public constructor(private titleService: Title) { }
    public setTitle (newTitle: string){
        this.titleService.setTitle( newTitle);
    }

    ngOnInit(): void {
        this.setTitle("Yazılım Geliştirme - Devex Medya");
    }

}
