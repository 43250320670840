<div class="navbar-area">
    <div class="mobile-nav">
        <a routerLink="/" class="logo"><img src="assets/img/logo/logo1.png" alt="Logo"></a>
    </div>

    <div class="main-nav nav-bar-two">
        <div class="container-fluid">
            <nav class="container-max-2 navbar navbar-expand-md navbar-light ">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/logo/logo1.png" alt="Logo" width="220"></a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav m-auto">
                        <li class="nav-item">
                            <a href="index.html" class="nav-link">Anasayfa <i ></i></a>


                        </li>

                        <li class="nav-item"><a routerLink="/hakkimizda" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Kurumsal <i class='bx bx-plus'></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/hakkimizda" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Hakkımızda</a></li>

                                <li class="nav-item"><a routerLink="/kalite-politikamiz" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Kalite Politikalarımız</a></li>

                                <li class="nav-item"><a routerLink="/cozum-ortaklarimiz" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Çözüm Ortaklarımız</a></li>
                                <li class="nav-item"><a routerLink="/takim-arkadaslarimiz" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Takım Arkadaşlarımız</a></li>

                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0);" class="nav-link">Hizmetlerimiz <i class='bx bx-plus'></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/web-tasarim" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Web Tasarım</a></li>

                                <li class="nav-item"><a routerLink="/web-programlama" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Web Programlama</a></li>

                                <li class="nav-item"><a routerLink="/e-ticaret" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">E-Ticaret</a></li>

                                <li class="nav-item"><a routerLink="/mobil-programlama" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Mobil Programlama</a></li>

                                <li class="nav-item"><a routerLink="/yazilim-gelistirme" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Yazılım Geliştirme</a></li>

                                <li class="nav-item"><a routerLink="/seo-optimizasyonu" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Seo Optimizasyonu</a></li>

                                <li class="nav-item"><a routerLink="/adwords-google-reklam" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Adwords Google Reklam</a></li>

                                <li class="nav-item"><a routerLink="/domain-mail-hosting" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Domain&Mail Hosting</a></li>

                                <li class="nav-item"><a routerLink="/bilisim-danismanligi" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Bilişim Danışmanlığı</a></li>

                                <li class="nav-item"><a routerLink="/privacy-policy" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Son Çalışmalarımız</a></li>


                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0);" class="nav-link">Çözümlerimiz <i class='bx bx-plus'></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/logo-tasarim" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Logo Tasarım</a></li>

                                <li class="nav-item"><a routerLink="/kurumsal-kimlik-tasarimi" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Kurumsal Kimlik Tasarımı</a></li>
                                <li class="nav-item"><a routerLink="/katalog-brosur-tasarimi" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Katalog&Broşür Tasarımı</a></li>
                                <li class="nav-item"><a routerLink="/marka-tescili" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Marka Tescili</a></li>
                                <li class="nav-item"><a routerLink="/baski-hizmetleri" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Baskı Hizmetleri</a></li>
                                <li class="nav-item"><a routerLink="/tanitim-filmi" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Tanıtım Filmi</a></li>
                            </ul>
                        </li>

                        <li class="nav-item">

                            <a href="javascript:void(0);" routerLink="/referanslarimiz" class="nav-link">Referanslarımız </a>

                        </li>

                        <li class="nav-item"><a routerLink="/iletisim" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">İletişim</a></li>
                    </ul>

                    <div class="side-nav d-in-line align-items-center">


                        <div class="side-item">
                            <div class="nav-add-btn">
                                <a routerLink="/iletisim" class="nav-menu-btn">İletişime Geçin <i class='bx bx-plus'></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>

    <div class="side-nav-responsive">
        <div class="container">
            <div class="dot-menu">

            </div>

            <div class="container">
                <div class="side-nav-inner">
                    <div class="side-nav justify-content-center  align-items-center">
                        <div class="side-item">
                            <div class="search-box">
                                <i class="flaticon-loupe"></i>
                            </div>
                        </div>
                        <div class="side-item">
                            <div class="user-btn">
                                <a routerLink="/"><i class="flaticon-contact"></i></a>
                            </div>
                        </div>
                        <div class="side-item">
                            <div class="nav-add-btn">
                                <a routerLink="/iletisim" class="nav-menu-btn">Contact Us <i class='bx bx-plus'></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="search-overlay">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="search-layer"></div>
            <div class="search-layer"></div>
            <div class="search-layer"></div>

            <div class="search-close">
                <span class="search-close-line"></span>
                <span class="search-close-line"></span>
            </div>

            <div class="search-form">
                <form>
                    <input type="text" class="input-search" placeholder="Search here...">
                    <button type="submit"><i class="flaticon-loupe"></i></button>
                </form>
            </div>
        </div>
    </div>
</div>
