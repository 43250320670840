import { Component, OnInit } from '@angular/core';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-kalite-politikamiz',
  templateUrl: './kalite-politikamiz.component.html',
  styleUrls: ['./kalite-politikamiz.component.scss']
})
export class KalitePolitikamizComponent implements OnInit {

    public constructor(private titleService: Title) { }
    public setTitle (newTitle: string){
        this.titleService.setTitle( newTitle);
    }

    ngOnInit(): void {
        this.setTitle("Kalite Politikamız - Devex Medya");
    }

}
