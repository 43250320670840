import { Component, OnInit } from '@angular/core';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-logo-tasarim',
  templateUrl: './logo-tasarim.component.html',
  styleUrls: ['./logo-tasarim.component.scss']
})
export class LogoTasarimComponent implements OnInit {

    public constructor(private titleService: Title) { }
    public setTitle (newTitle: string){
        this.titleService.setTitle( newTitle);
    }

    ngOnInit(): void {
        this.setTitle("Logo Tasarım - Devex Medya");
    }

}
