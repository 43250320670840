<div class="col-lg-4 col-md-12">
    <div class="services-widget-right">
        <div class="widget-category">
            <h3>Other Services</h3>

            <ul>
                <li><a routerLink="/services-details">AI & ML Development</a></li>
                <li><a routerLink="/services-details">Data Visualization</a></li>
                <li><a routerLink="/services-details">Data Science</a></li>
                <li><a routerLink="/services-details">Content Marketing</a></li>
                <li><a routerLink="/services-details">SEO & Internet</a></li>
            </ul>
        </div>

        <div class="contact-widget">
            <h2>Contact Info</h2>

            <ul>
                <li>
                    <i class="flaticon-telephone"></i>
                    <div class="content">
                        <h3>Phone: </h3>
                        <span><a href="tel:+564325677896">+56432 567 7896</a></span>
                    </div>
                </li>
                <li>
                    <i class="flaticon-email-1"></i>
                    <div class="content">
                        <h3>Email:</h3>
                        <span><a href="mailto:hello@ribo.com">hello@ribo.com</a></span>
                    </div>
                </li>
                <li>
                    <i class="flaticon-planet-earth"></i>
                    <div class="content">
                        <h3>Location:</h3>
                        <span>112/7 New York, USA</span>
                    </div>
                </li>
            </ul>
        </div>

        <div class="services-widget-two">
            <h2>Brochures</h2>

            <ul>
                <li>
                    <i class="flaticon-pdf-file"></i>
                    <div class="content">
                        <h3>01:</h3>
                        <span><a routerLink="/services-details">PDF Download</a></span>
                    </div>
                </li>
                <li>
                    <i class="flaticon-pdf-file"></i>
                    <div class="content">
                        <h3>02:</h3>
                        <span><a routerLink="/services-details">Services Details.txt</a></span>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</div>
