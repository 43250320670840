import { Component, OnInit } from '@angular/core';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-seo-agency',
  templateUrl: './seo-agency.component.html',
  styleUrls: ['./seo-agency.component.scss']
})
export class SeoAgencyComponent implements OnInit {

    public constructor(private titleService: Title) { }
    public setTitle (newTitle: string){
        this.titleService.setTitle( newTitle);
    }

    ngOnInit(): void {
        this.setTitle("Devex Medya Bilişim Ve Yazılım Hizmetleri");
    }

}
