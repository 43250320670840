<app-navbar-style-two></app-navbar-style-two>

<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Domain & Mail Hosting</h3>
            <ul>
                <li><a href="https://www.devexmedya.com">Anasayfa</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Domain & Mail Hosting</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="services-details-area pt-50 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="services-left">
                    <div class="services-content">
                        <img src="assets/img/service/domain-maiil.jpg" alt="Images">
                        <h2>Domain & Mail Hosting</h2>
                        <p>Birey, kurum ve kuruluşlar en basit anlamda bilgi yayınlamak ve e-posta alışverişi yapmak; ayrıca e-ticaret uygulamaları, interaktif işlemler ve daha birçok amaçla internet ortamında tek bir adresi tarif eden domain yani alan adı isimlerini günümüzde yaygın olarak kullanıyorla</p>
                    </div>

                    <div class="row">
                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <h3>Windows Hosting Nedir?</h3>
                                <p>Windows Hosting servisi, üzerinde Windows işletim sisteminin çalıştığı hosting sunucular üzerinde barındırılan hosting hizmetidir. Telehouse Istanbul Data Center’ımızda konumlandırılan Windows sunucuları; PHP, CGI, ASP ve ASP.NET gibi yazılım teknolojilerini ve MS Access, MSSQL, MySQL gibi veri tabanlarını desteklemektedir</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <h3>Linux Hosting Nedir?</h3>
                                <p>Linux Hosting servisi ise Linux işletim sistemi üzerinde çalışmakta, web sunucusu olarak Apache, veritabanı olarak MySQL kullanılmaktadır. Linux Hosting, HTML, HTM ve PHP uygulamalarını destekler. PHP uygulaması Windows Hosting üzerinde de çalışmakla beraber asıl verimi Linux Hosting üzerinde sağlanmaktadır.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <h3>Alan Adı Tescili</h3>
                                <p>Firmanıza ait web sitenizi internet üzerinde yayınlamak için bir alan adına sahip olmanız gerekir. Bir alan adı uzantısı aynı ada birden çok şahıs/firma tarafından kullanılamaz. Bu sebeple istediğiniz alan adının başkaları tarafından kullanılmaması için alan adını tescil ettirmeniz gerekmektedir. Alan adları birden fazla uzantı ile tescil edilebilir. (örnek: www.devexmedya.com, www.devexmedya.net vb.)</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <h3>Anında Aktivasyon</h3>
                                <p>Alan adınızı, satın alma işlemi tamamlandıktan sonra hemen kullanmaya başlarsınız. Sadece .tr ve .xxx uzantılı alan adları için bazı evraklar gerekmektedir. Evrakların tarafımıza ulaştırılması ardından alan adınız çok kısa bir sürede aktif edilir.</p>
                            </div>
                        </div>
                    </div>

                    <div class="content-widget-area">
                        <div class="row">
                            <div class="col-lg-7 col-md-12">
                                <div class="content-widget-img">
                                    <img src="assets/img/service/service-details-img2.png" alt="Images">
                                </div>
                            </div>

                            <div class="col-lg-5 col-md-12">
                                <div class="content-widget-text">
                                    <h2>Neden Bizi Seçmelisiniz?</h2>
                                    <p></p>
                                    <ul>
                                        <li><i class='bx bx-check'></i> Özgün Tasarım</li>
                                        <li><i class='bx bx-check'></i> Tam Mobil Uyum  </li>
                                        <li><i class='bx bx-check'></i> Temiz Kodlama </li>

                                        <li><i class='bx bx-check'></i> Web Standartları </li>
                                        <li><i class='bx bx-check'></i> Seo Etkisi </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <p>Web yazılım konusunda uzman ekibimiz sizin istekleriniz doğrultusunda size özel bir web yazılım çözümü sunmaktadır. Baştan sona oldukça kullanılabilir ve yüksek teknoloji kullanılmış bu ürünlerimizi denemek isterseniz lütfen bizim ile iletişime geçin.</p>
                </div>
            </div>

            <app-servilersag></app-servilersag>
        </div>
    </div>
</div>


<app-footer-style-one></app-footer-style-one>
