import { Component, OnInit } from '@angular/core';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-katalog-brosur-tasarimi',
  templateUrl: './katalog-brosur-tasarimi.component.html',
  styleUrls: ['./katalog-brosur-tasarimi.component.scss']
})
export class KatalogBrosurTasarimiComponent implements OnInit {

    public constructor(private titleService: Title) { }
    public setTitle (newTitle: string){
        this.titleService.setTitle( newTitle);
    }

    ngOnInit(): void {
        this.setTitle("Katalog & Broşür Taasarımı - Devex Medya");
    }

}
