<div class="col-lg-12 col-md-12">
    <div class="services-widget-right">
        <div class="widget-category">
            <h3>Hizmetlerimiz</h3>

            <ul>
                <li><a routerLink="/logo-tasarim">Logo Tasarım</a></li>
                <li><a routerLink="/kurumsal-kimlik-tasarimi">Kurumsal Kimlik Tasarımı</a></li>
                <li><a routerLink="/katalog-brosur-tasarimi">Katalog&Broşür Tasarımı</a></li>
                <li><a routerLink="/marka-tescili">Marka Tescili</a></li>
                <li><a routerLink="/baski-hizmetleri">Baskı Hizmetleri</a></li>
                <li><a routerLink="/tanitim-filmi">Tanıtım Filmi</a></li>

            </ul>
        </div>

        <div class="contact-widget">
            <h2>İletişim</h2>

            <ul>
                <li>
                    <i class="flaticon-telephone"></i>
                    <div class="content">
                        <h3>Telefon: </h3>
                        <span><a href="tel:+902827262538">(+90)282 726 25 38</a></span>
                    </div>
                </li>
                <li>
                    <i class="flaticon-email-1"></i>
                    <div class="content">
                        <h3>Mail:</h3>
                        <span><a href="mailto:info@devexmedya.com">info@devexmedya.com</a></span>
                    </div>
                </li>
                <li>
                    <i class="flaticon-planet-earth"></i>
                    <div class="content">
                        <h3>Adres:</h3>
                        <span>Gabralı İş Merkezi<br>Çerkezköy/Tekirdağ</span>
                    </div>
                </li>
            </ul>
        </div>

    </div>
</div>
