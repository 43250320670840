import { Component, OnInit } from '@angular/core';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-referanslarimiz',
  templateUrl: './referanslarimiz.component.html',
  styleUrls: ['./referanslarimiz.component.scss']
})
export class ReferanslarimizComponent implements OnInit {

    public constructor(private titleService: Title) { }
    public setTitle (newTitle: string){
        this.titleService.setTitle( newTitle);
    }

    ngOnInit(): void {
        this.setTitle("Referanslarımız - Devex Medya");
    }

}
