import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SeoAgencyComponent } from './components/pages/seo-agency/seo-agency.component';
import { AiStartupComponent } from './components/pages/ai-startup/ai-startup.component';
import { MachineLearningComponent } from './components/pages/machine-learning/machine-learning.component';
import { PreloaderComponent } from './components/common/preloader/preloader.component';
import { NavbarStyleOneComponent } from './components/common/navbar-style-one/navbar-style-one.component';
import { FooterStyleOneComponent } from './components/common/footer-style-one/footer-style-one.component';
import { NavbarStyleTwoComponent } from './components/common/navbar-style-two/navbar-style-two.component';
import { FooterStyleTwoComponent } from './components/common/footer-style-two/footer-style-two.component';
import { NavbarStyleThreeComponent } from './components/common/navbar-style-three/navbar-style-three.component';
import { AboutComponent } from './components/pages/about/about.component';
import { TeamComponent } from './components/pages/team/team.component';
import { PricingComponent } from './components/pages/pricing/pricing.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { TestimonialsComponent } from './components/pages/testimonials/testimonials.component';
import { CaseStudyComponent } from './components/pages/case-study/case-study.component';
import { CaseStudyDetailsComponent } from './components/pages/case-study-details/case-study-details.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { SignInComponent } from './components/pages/sign-in/sign-in.component';
import { SignUpComponent } from './components/pages/sign-up/sign-up.component';
import { TermsConditionsComponent } from './components/pages/terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './components/pages/privacy-policy/privacy-policy.component';
import { ComingSoonComponent } from './components/pages/coming-soon/coming-soon.component';
import { ServicesComponent } from './components/pages/services/services.component';
import { ServicesDetailsComponent } from './components/pages/services-details/services-details.component';
import { BlogDetailsComponent } from './components/pages/blog-details/blog-details.component';
import { BlogComponent } from './components/pages/blog/blog.component';
import { IletisimComponent } from './components/pages/iletisim/iletisim.component';
import { ServislersagComponent } from './components/common/servislersag/servislersag.component';
import { ServilersagComponent } from './components/pages/servilersag/servilersag.component';
import { WebTasarimComponent } from './components/pages/web-tasarim/web-tasarim.component';
import { WebProgramlamaComponent } from './components/pages/web-programlama/web-programlama.component';
import { ETicaretComponent } from './components/pages/e-ticaret/e-ticaret.component';
import { MobilProgramlamaComponent } from './components/pages/mobil-programlama/mobil-programlama.component';
import { YazilimGelistirmeComponent } from './components/pages/yazilim-gelistirme/yazilim-gelistirme.component';
import { SeoOptimizasyonuComponent } from './components/pages/seo-optimizasyonu/seo-optimizasyonu.component';
import { AdwordsGoogleReklamComponent } from './components/pages/adwords-google-reklam/adwords-google-reklam.component';
import { DomainMailHostingComponent } from './components/pages/domain-mail-hosting/domain-mail-hosting.component';
import { BilisimDanismanligiComponent } from './components/pages/bilisim-danismanligi/bilisim-danismanligi.component';
import { KalitePolitikamizComponent } from './components/pages/kalite-politikamiz/kalite-politikamiz.component';
import { CozumOrtaklarimizComponent } from './components/pages/cozum-ortaklarimiz/cozum-ortaklarimiz.component';
import { HakkimizdaComponent } from './components/pages/hakkimizda/hakkimizda.component';
import { HizmetlerimizComponent } from './components/pages/hizmetlerimiz/hizmetlerimiz.component';
import { LogoTasarimComponent } from './components/pages/logo-tasarim/logo-tasarim.component';
import { CozumlersagComponent } from './components/pages/cozumlersag/cozumlersag.component';
import { KurumsalKimlikTasarimiComponent } from './components/pages/kurumsal-kimlik-tasarimi/kurumsal-kimlik-tasarimi.component';
import { KatalogBrosurTasarimiComponent } from './components/pages/katalog-brosur-tasarimi/katalog-brosur-tasarimi.component';
import { MarkaTesciliComponent } from './components/pages/marka-tescili/marka-tescili.component';
import { BaskiHizmetleriComponent } from './components/pages/baski-hizmetleri/baski-hizmetleri.component';
import { TanitimFilmiComponent } from './components/pages/tanitim-filmi/tanitim-filmi.component';
import { ReferanslarimizComponent } from './components/pages/referanslarimiz/referanslarimiz.component';
import { TakimArkadaslarimizComponent } from './components/pages/takim-arkadaslarimiz/takim-arkadaslarimiz.component';

@NgModule({
  declarations: [
    AppComponent,
    SeoAgencyComponent,
    AiStartupComponent,
    MachineLearningComponent,
    PreloaderComponent,
    NavbarStyleOneComponent,
    FooterStyleOneComponent,
    NavbarStyleTwoComponent,
    FooterStyleTwoComponent,
    NavbarStyleThreeComponent,
    AboutComponent,
    TeamComponent,
    PricingComponent,
    FaqComponent,
    TestimonialsComponent,
    CaseStudyComponent,
    CaseStudyDetailsComponent,
    ErrorComponent,
    SignInComponent,
    SignUpComponent,
    TermsConditionsComponent,
    PrivacyPolicyComponent,
    ComingSoonComponent,
    ServicesComponent,
    ServicesDetailsComponent,
    BlogDetailsComponent,
    BlogComponent,
    IletisimComponent,
    ServislersagComponent,
    ServilersagComponent,
    WebTasarimComponent,
    WebProgramlamaComponent,
    ETicaretComponent,
    MobilProgramlamaComponent,
    YazilimGelistirmeComponent,
    SeoOptimizasyonuComponent,
    AdwordsGoogleReklamComponent,
    DomainMailHostingComponent,
    BilisimDanismanligiComponent,
    KalitePolitikamizComponent,
    CozumOrtaklarimizComponent,
    HakkimizdaComponent,
    HizmetlerimizComponent,
    LogoTasarimComponent,
    CozumlersagComponent,
    KurumsalKimlikTasarimiComponent,
    KatalogBrosurTasarimiComponent,
    MarkaTesciliComponent,
    BaskiHizmetleriComponent,
    TanitimFilmiComponent,
    ReferanslarimizComponent,
    TakimArkadaslarimizComponent,

  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
