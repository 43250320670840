<app-navbar-style-two></app-navbar-style-two>

<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Katalog & Broşür Tasarımı</h3>
            <ul>
                <li><a href="https://www.devexmedya.com">Anasayfa</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Katalog & Broşür Tasarımı</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="services-details-area pt-50 pb-70">
    <div class="container">
        <div class="row">
            <app-cozumlersag></app-cozumlersag>
            <div class="col-lg-8 col-md-12">

                <div class="services-left">
                    <div class="services-content">
                        <img src="assets/img/service/katolog.jpg" alt="Images">
                        <h2>Katalog & Broşür Tasarımı</h2>
<p>Katalog, broşür, el ilanı, föy, faaliyet raporu, kitap vb. çalışmaları kurumunuza özgü tasarımlarla hazırlamakta ve sunmaktayız.
<br>
    Kurumunuzun faaliyetlerini yansıtan, sizi müşterilerinize anlatan çalışmaları sizden aldığımız materyallerle ekibimizdeki profesyonel tasarımcı ve metin yazarlarıyla beraber değerlendirip; kurumunuza veya ürününüze en uygun şekilde hazırlamaktayız.
<br>
    Dinamik ve profesyonel ekibimiz ile sizlere hizmet vermekteyiz.</p>                    </div>

                    <div class="row">
                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <h3>Bize şirketinizden bahsedin!</h3>
                                <p>İnteraktif ve yaratıcı yarışma sistemimiz herşeyi çok kolaylaştırır bize bakış açınızı anlatın bütçenizi belirleyin biz de size en güzel fikirleri sunalım</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <h3>Yüzlerce alternatif alın!</h3>
                                <p>Profesyonel tasarımcı ve metin yazarlarımız size fikirlerini göndermeye başlasın siz de onlarla iş birliği yaparak yarışmanızı şekillendirin.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <h3>En beğendiğinizi seçin!</h3>
                                <p>100'lerce alternatif arasından en beğendiğinizi seçin. Vektörel, baskıya uygun dosyalarınızı teslim alırsınız.</p>                            </div>
                        </div>


                    </div>

                    <div class="content-widget-area">
                        <div class="row">
                            <div class="col-lg-7 col-md-12">
                                <div class="content-widget-img">
                                    <img src="assets/img/service/service-details-img2.png" alt="Images">
                                </div>
                            </div>

                            <div class="col-lg-5 col-md-12">
                                <div class="content-widget-text">
                                    <h2>Neden Bizi Seçmelisiniz?</h2>
                                    <p></p>
                                    <ul>
                                        <li><i class='bx bx-check'></i> Özgün Tasarım</li>
                                        <li><i class='bx bx-check'></i> Tam Mobil Uyum  </li>
                                        <li><i class='bx bx-check'></i> Temiz Kodlama </li>

                                        <li><i class='bx bx-check'></i> Web Standartları </li>
                                        <li><i class='bx bx-check'></i> Seo Etkisi </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <p>Web yazılım konusunda uzman ekibimiz sizin istekleriniz doğrultusunda size özel bir web yazılım çözümü sunmaktadır. Baştan sona oldukça kullanılabilir ve yüksek teknoloji kullanılmış bu ürünlerimizi denemek isterseniz lütfen bizim ile iletişime geçin.</p>
                </div>
            </div>


        </div>
    </div>
</div>


<app-footer-style-one></app-footer-style-one>
