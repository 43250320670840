<app-navbar-style-two></app-navbar-style-two>

<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Bilişim Danışmanlığı</h3>
            <ul>
                <li><a href="https://www.devexmedya.com">Anasayfa</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Bilişim Danışmanlığı</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="services-details-area pt-50 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="services-left">
                    <div class="services-content">
                        <img src="assets/img/service/bilisim-danismanliği.jpg" alt="Images">
                        <h2>Bilişim Danışmanlığı</h2>
                        <p>"Hızla gelişmekte olan dijital medyanın avantajlarını nasıl kullanacağınız konusunda sizlere profesyonel danışmanlık hizmetleri sunuyoruz."<br><br>Web sitesi tasarımı, google ve diğer arama motorları araçları ile ürün ve hizmetlerinizin tanıtımı, firmanızın iş süreçlerini web üzerinden takip edebileceğiniz yazılımların yönetimi konularında bilişim danışmanlığı hizmeti veriyoruz.
                        <br>İhtiyaçlarınızı belirleyip, gerekli analizleri yaparak öncelikle sizin için en uygun çözümü bir proje halinde size sunuyoruz. Sizin kendi departmanınız gibi davranıyor, sizin için düşünüyor ve sizin için size özel, projeler geliştiriyoruz.</p>
                   <blockquote>Bu alanda verdiğimiz hizmet başlıkları şöyledir;<br><br>

                       Alan adı, hosting ve server hizmetleri<br>
                       Web tabanlı proje planlama ve yönetimi,<br>
                       Web tabanlı iş süreç yönetimi<br>
                       Web sitesi tasarım ve programlama<br>
                       Veri tabanı uygulamaları<br>
                       E-Ticaret (E-Commerce)<br>
                       Bilgi ve Doküman Yönetim Sistemleri (Knowledge and Document Management)<br>
                       İçerik yönetim sistemleri (Content Management System)<br>
                       İçerik Editörlüğü<br>
                       Arama Motorları Optimizasyonu (SEO)<br>
                       Google Adwords Reklam Yönetimi<br>
                       Marka Yönetimi<br>
                       Mobil Uygulama Geliştirme<br>
                       Süreç İyileştirme (Firmanıza yazılım süreçlerinin analiz edilerek iyileştirilmesi.)<br>
                       Yazılım Denetleme ve İyileştirme ( Mevcut yazılımlarınızı tarafsız olarak denetleme ve yapılacak analizden sonra  uygulamadaki tıkanıklıklar, oluşturdukları mevcut ve potansiyel riskler, ve önerilerimizi içeren bir iyileştirme planı sunma. )<br>
                       Yazılım  Geliştirme ( Yazılımlarınızın, zaman planlaması yapılarak, yüksek kalite ve tutarlılıkla geliştirilmesini sağlama)<br>
                       Kullanıcı Eğitimleri (Hazırlanan yazılımın kullanımı konusunda saptanan personele verilecek eğitimler)<br>
                       Proje Destek ve Bakım Sözleşmesi.</blockquote>
                    </div>

                    <div class="row">
                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <h3>Hazır Web Sitesi</h3>
                                <p>Hızlı ve ekonomik web tasarım çözümleri arayan firmalar için yüzlerce çalışma arasından seçme şansı sunuyoruz.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <h3>Online Ödeme Sistemi</h3>
                                <p>Ödeme Sistemi, kredi kartı ile online ödemeleri kabul etmenize olanak sağlayan web tabanlı bir uygulama. Yakında!</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <h3>Özel Yazılımlar</h3>
                                <p>Firmanızın ihtyaçlarına yönelik, işlerinizi kolaylaştıracak heryerden ulaşılabilir web tabanlı yazılım çözümlerimizi deneyin.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <h3>Emlak Web Sitesi</h3>
                                <p>Profesyonel emlak yazılımı, emlak ofislerinin ihtiyaçlarına yönelik hazırlanan emlak web sitesi ve aynı zamanda mobil emlak sitesidir</p>
                            </div>
                        </div>
                    </div>

                    <div class="content-widget-area">
                        <div class="row">
                            <div class="col-lg-7 col-md-12">
                                <div class="content-widget-img">
                                    <img src="assets/img/service/service-details-img2.png" alt="Images">
                                </div>
                            </div>

                            <div class="col-lg-5 col-md-12">
                                <div class="content-widget-text">
                                    <h2>Neden Bizi Seçmelisiniz?</h2>
                                    <p></p>
                                    <ul>
                                        <li><i class='bx bx-check'></i> Özgün Tasarım</li>
                                        <li><i class='bx bx-check'></i> Tam Mobil Uyum  </li>
                                        <li><i class='bx bx-check'></i> Temiz Kodlama </li>

                                        <li><i class='bx bx-check'></i> Web Standartları </li>
                                        <li><i class='bx bx-check'></i> Seo Etkisi </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <p>Web yazılım konusunda uzman ekibimiz sizin istekleriniz doğrultusunda size özel bir web yazılım çözümü sunmaktadır. Baştan sona oldukça kullanılabilir ve yüksek teknoloji kullanılmış bu ürünlerimizi denemek isterseniz lütfen bizim ile iletişime geçin.</p>
                </div>
            </div>

            <app-servilersag></app-servilersag>
        </div>
    </div>
</div>


<app-footer-style-one></app-footer-style-one>
