<app-navbar-style-two></app-navbar-style-two>

<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Hakkımızda</h3>
            <ul>
                <li><a href="https://www.devexmedya.com">Anasayfa</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Hakkımızda</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="about-widget-area pt-50 pb-70">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-widget-img">
                    <img src="assets/img/about/about-img4.png" alt="">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-widget-content">
                    <div class="section-title">
                        <span class="sp-before sp-after">Hakkımızda</span>
                        <h2>Gelin İşinizi Beraber Büyütelim</h2>
                    </div>
                    <h3>Devex Medya, 2010 yılından beri işletmelere kesintisiz entegre çözümler sunan bir Web tasarım, SEO ve Yazılım geliştirme ajansıdır</h3>
                    <p></p>
                    <div class="about-widget-list">
                        <ul>
                            <li>
                                <i class='bx bx-check'></i>
                                <span>01</span>
                                <p>Mevcut bir fikirden yeni yaratılan bir konsepte kadar, tüm aşamalarda yaratıcı ve kusursuz çözümler geliştiriyoruz.</p>
                            </li>
                            <li>
                                <i class='bx bx-check'></i>
                                <span>02</span>
                                <p>Ceo Yazılım çalışanları her gün yeni bir şeyler öğrenmek, kendilerini sürekli geliştirmek anlayışı ile çalışıyorlar.</p>
                            </li>
                            <li>
                                <i class='bx bx-check'></i>
                                <span>03</span>
                                <p>Projelerimizi, deneyimimizi ve bilgilerimizi ekibimiz ve müşterilerimizle paylaşarak hayata geçiriyoruz.</p>
                            </li>
                        </ul>
                    </div>
                    <div class="about-video">
                        <a href="https://www.youtube.com/watch?v=07d2dXHYb94&t=6s" class="video-btn popup-btn"><i class='flaticon-forward'></i><span>Tanıtım Videomuz </span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="counter-area-two pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>10</h3>
                    <p>Yıllık Tecrübe</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>%100</h3>
                    <p>Memnuniyet</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>+15</h3>
                    <p>Takım Arkadaşı</p>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6 col-md-3">
                <div class="counter-card">
                    <h3>+25</h3>
                    <p>Prosfesyonel Hizmet</p>
                </div>
            </div>
        </div>
    </div>

    <div class="counter-shape-top">
        <img src="assets/img/counter/counter-shape.png" alt="Images">
    </div>
</div>

<div class="service-list-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Değerlerimiz</span>
            <h2>Nasıl Mı Bu Kadar İyiyiz?</h2>
        </div>

        <div class="row pt-45">
            <div class="col-lg-4 col-md-6">
                <div class="service-list-content">
                    <h3>Misyonumuz</h3>
                    <p>Devex Medya, yenilikçi ve teknolojik çözümleriyle kurumların bilgi teknolojileri destekli veriler kullanılarak yönetilmesine destek olmayı ve
                        uluslararası rekabet gücünün artırılmasına katkıda bulunmayı hedefler.

                    </p>
                    <ul>
                        <li><i class='bx bx-check'></i> Müşteri Odaklılık</li>
                        <li><i class='bx bx-check'></i>Sürekli öğrenme</li>
                        <li><i class='bx bx-check'></i>Takım Çalışması </li>
                        <li><i class='bx bx-check'></i> Güvenilirlik</li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="service-list-content">
                    <h3>Biz Kimiz</h3>
                    <p>Devex Medya, 2010 yılından beri işletmelere kesintisiz entegre çözümler sunan bir Web tasarım, SEO ve Yazılım geliştirme ajansıdır</p>
                    <ul>
                        <li><i class='bx bx-check'></i> Doğru Arge</li>
                        <li><i class='bx bx-check'></i> Çöüzm Odaklı Olma</li>
                        <li><i class='bx bx-check'></i> Planlı Çalışma</li>
                        <li><i class='bx bx-check'></i> Memnun Müşteri</li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="service-list-content">
                    <h3>Vizyonumuz</h3>
                    <p>Konusunda uzman ve tecrübeli kadromuzla bilgi teknolojileri alanında yenilikçi, dürüst, memnuniyete önem veren ve çoğu kesim tarafından basite indirgenen hizmet alanlarımızın önemini kanıtlayan bir firma olmak</p>
                    <ul>
                        <li><i class='bx bx-check'></i> Yenilikçi</li>
                        <li><i class='bx bx-check'></i> Mükemmelliği Arayan</li>
                        <li><i class='bx bx-check'></i> Saygın</li>
                        <li><i class='bx bx-check'></i> Güvenilirlik</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>



<div class="team-area-two pb-70">
    <div class="container">
        <div class="section-title text-center">
            <span class="sp-before sp-after">Çalışma Arkadaşlarımız</span>
            <h2 class="h2-color2">Gelin Tanışalım</h2>
        </div>

        <div class="team-slider-two owl-carousel owl-theme">
            <div class="team-card active">
                <img src="assets/img/team/team-img1.jpg" alt="Images">
                <div class="content">
                    <h3>Mehmet Yurdakul</h3>
                    <span>Back-End Developer</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="team-card team-rotated">
                <img src="assets/img/team/team-img2.jpg" alt="Images">
                <div class="content">
                    <h3>Veysel EROL</h3>
                    <span>Creative Drictor</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="team-card">
                <img src="assets/img/team/team-img3.jpg" alt="Images">
                <div class="content">
                    <h3>Sevdanur Çakır</h3>
                    <span>Web Designer</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="team-card team-rotated-2">
                <img src="assets/img/team/team-img4.jpg" alt="Images">
                <div class="content">
                    <h3>Doğukan KELEŞ</h3>
                    <span>CEO Specialist</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="team-card active">
                <img src="assets/img/team/team-img5.jpg" alt="Images">
                <div class="content">
                    <h3>Emir POLAT</h3>
                    <span>Graphic Designer</span>
                    <ul class="social-link">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                    </ul>
                </div>
            </div>


        </div>
    </div>

    <div class="team-two-shape">
        <div class="shape1"><img src="assets/img/shape/shape13.png" alt="Images"></div>
        <div class="shape2"><img src="assets/img/shape/shape11.png" alt="Images"></div>
        <div class="shape3"><img src="assets/img/shape/shape10.png" alt="Images"></div>
        <div class="shape4"><img src="assets/img/shape/shape9.png" alt="Images"></div>
        <div class="shape5"><img src="assets/img/shape/shape12.png" alt="Images"></div>
        <div class="shape6"><img src="assets/img/shape/shape7.png" alt="Images"></div>
        <div class="shape7"><img src="assets/img/shape/shape14.png" alt="Images"></div>
    </div>
</div>

<div class="testimonial-area testimonial-area-mb ptb-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="testimonial-list">
                    <div class="list-img-1">
                        <img src="assets/img/testimonial/testimonial1.png" alt="Images">
                    </div>

                    <div class="list-img-2">
                        <img src="assets/img/testimonial/testimonial2.png" alt="Images">
                    </div>

                    <div class="list-img-3">
                        <img src="assets/img/testimonial/testimonial3.png" alt="Images">
                    </div>

                    <div class="list-img-4">
                        <img src="assets/img/testimonial/testimonial4.png" alt="Images">
                    </div>

                    <div class="list-img-5">
                        <img src="assets/img/testimonial/testimonial5.png" alt="Images">
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="testimonial-title">
                    <h2>Bizim İçin Ne Dediler..</h2>
                </div>

                <div class="testimonial-slider owl-carousel owl-theme">
                    <div class="testimonial-item">
                        <p >VM BİLİŞİM'DEN WEB TASARIM HİZMETLERİ ALDIK, İLK ETAPTA İLETİŞİM PROBLEMİ YAŞANSA DA PROJEMİZ VAADEDİLEN SÜREDE TAMAMLANDI. TEŞEKKÜR EDERİZ...</p>
                        <div class="content">
                            <img src="assets/img/testimonial/testimonial-img1.png" alt="Images">
                            <div class="content-title">
                                <h3>Ahmet ALTIN</h3>
                                <span>MİMAR</span>
                            </div>
                        </div>
                    </div>

                    <div class="testimonial-item">
                        <p>DEVEX MEDYA İLE MOBİL UYGULAMA İHTİYACIMIZDAN DOLAYI TANIŞTIK. EKSTRA VARYASYONLARI VERDİKLERİ HİZMET DAHİLİNDE EKLEYEREK BÜYÜK KATKI SAĞLADILAR.</p>
                        <div class="content">
                            <img src="assets/img/testimonial/testimonial-img2.png" alt="Images">
                            <div class="content-title">
                                <h3>Fitnat TELLİ</h3>
                                <span>İşletmeci</span>
                            </div>
                        </div>
                    </div>

                    <div class="testimonial-item">
                        <p>VM BİLİŞİMLE MOBİL UYGULAMA İHTİYACIMIZDAN DOLAYI TANIŞTIK. EKSTRA VARYASYONLARI VERDİKLERİ HİZMET DAHİLİNDE EKLEYEREK BÜYÜK KATKI SAĞLADILAR.</p>
                        <div class="content">
                            <img src="assets/img/testimonial/testimonial-img3.png" alt="Images">
                            <div class="content-title">
                                <h3>Said YURDAKUL</h3>
                                <span>ÖZYURDAKUL RESTORANT</span>
                            </div>
                        </div>
                    </div>
                    <div class="testimonial-item">
                        <p>DAHA ÖNCE VAR OLAN WEB SİTEMİZE BAMBAŞKA BİR BOYUT KAZANDIRAN VM BİLİŞİM VE EKİPLERİNE TEŞEKKÜRLER.</p>
                        <div class="content">
                            <img src="assets/img/testimonial/testimonial-img3.png" alt="Images">
                            <div class="content-title">
                                <h3>LAMİA ÇOBAN</h3>
                                <span>REKLAM AJANSI</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<app-footer-style-one></app-footer-style-one>
