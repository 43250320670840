import { Component, OnInit } from '@angular/core';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-web-tasarim',
  templateUrl: './web-tasarim.component.html',
  styleUrls: ['./web-tasarim.component.scss']
})
export class WebTasarimComponent implements OnInit {
    public constructor(private titleService: Title) { }
    public setTitle (newTitle: string){
        this.titleService.setTitle( newTitle);
    }

    ngOnInit(): void {
        this.setTitle("Web Tasarım - Devex Medya");
    }

}
