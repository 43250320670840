import { Component, OnInit } from '@angular/core';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-marka-tescili',
  templateUrl: './marka-tescili.component.html',
  styleUrls: ['./marka-tescili.component.scss']
})
export class MarkaTesciliComponent implements OnInit {
    public constructor(private titleService: Title) { }
    public setTitle (newTitle: string){
        this.titleService.setTitle( newTitle);
    }

    ngOnInit(): void {
        this.setTitle("Marka Tescili - Devex Medya");
    }

}
