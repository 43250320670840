import { Component, OnInit } from '@angular/core';
import {Title} from '@angular/platform-browser';

@Component({
  selector: 'app-mobil-programlama',
  templateUrl: './mobil-programlama.component.html',
  styleUrls: ['./mobil-programlama.component.scss']
})
export class MobilProgramlamaComponent implements OnInit {

    public constructor(private titleService: Title) { }
    public setTitle (newTitle: string){
        this.titleService.setTitle( newTitle);
    }

    ngOnInit(): void {
        this.setTitle("Mobil Programlama - Devex Medya");
    }

}
