<app-navbar-style-two></app-navbar-style-two>

<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Web Tasarım</h3>
            <ul>
                <li><a href="https://www.devexmedya.com">Anasayfa</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Web Tasarım</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="services-details-area pt-50 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="services-left">
                    <div class="services-content">
                        <img src="assets/img/service/web-tasarim.jpg" alt="Images">
                        <h2>Web Tasarım</h2>
                        <p><b>Devex Medya</b>, benzersiz, seçkin, amaca uygun, güncel web tasarım trendlerine uygun işler yapmaktadır. Bu prensip, Devex Medya web tasarım kültürünün temelidir.</p>
                    </div>

                    <div class="row">
                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <h3>Hazır İçerik Yönetim Sistemi</h3>
                                <p>Kendi içerik yönetim sistemini yazdırarak vakit kaybetmek istemeyen müşterilerimize, hazır wordpress ve türevi paket alternatifleri sunuyoruz.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <h3>Temiz ve Profesyonel Web Tasarım</h3>
                                <p>Web sitenizi, müşterilerinizin kolaylıkla kullanabilmesi için kullanıcı deneyimine uygun olarak tasarlıyoruz.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <h3>Ücretsiz Destek & Güncelleme</h3>
                                <p>Bizden aldığınız her türlü hizmet süresince yanınızdayız. Her an destek alabilmeniz için 7/24 buradayız.</p>
                            </div>
                        </div>

                        <div class="col-lg-6 col-sm-6">
                            <div class="services-content-card">
                                <h3>İletişim Yönetimi</h3>
                                <p>Web sitenizde oluşturulan formlar sayesinde müşterilerinizle kolaylıkla iletişime geçebilir ve onlardan bilgi alabilirsiniz.</p>
                            </div>
                        </div>
                    </div>

                    <div class="content-widget-area">
                        <div class="row">
                            <div class="col-lg-7 col-md-12">
                                <div class="content-widget-img">
                                    <img src="assets/img/service/service-details-img2.png" alt="Images">
                                </div>
                            </div>

                            <div class="col-lg-5 col-md-12">
                                <div class="content-widget-text">
                                    <h2>Neden Bizi Seçmelisiniz?</h2>
                                    <p></p>
                                    <ul>
                                        <li><i class='bx bx-check'></i> Özgün Tasarım</li>
                                        <li><i class='bx bx-check'></i> Tam Mobil Uyum  </li>
                                        <li><i class='bx bx-check'></i> Temiz Kodlama </li>

                                        <li><i class='bx bx-check'></i> Web Standartları </li>
                                        <li><i class='bx bx-check'></i> Seo Etkisi </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <p>Amacımız ihtiyacınız olan Web Tasarımı animasyonlu görsellerin ahenkli uyumunu sağlayan, içerik ile de verilmek istenen mesajın ve bilgilerin estetik ve teknik mükemmelliğini sağlayan işler çıkarmaktadır.</p>
                </div>
            </div>

            <app-servilersag></app-servilersag>
        </div>
    </div>
</div>


<app-footer-style-one></app-footer-style-one>
