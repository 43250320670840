<app-navbar-style-two></app-navbar-style-two>

<div class="inner-banner">
    <div class="container">
        <div class="inner-title text-center">
            <h3>Kalite Politikamız</h3>
            <ul>
                <li><a href="https://www.devexmedya.com">Anasayfa</a></li>
                <li><i class='bx bx-chevron-right'></i></li>
                <li>Kalite Politikamız</li>
            </ul>
        </div>
    </div>

    <div class="inner-banner-shape">
        <div class="shape-one"><img src="assets/img/inner-banner/banner-shape1.png" alt="Images"></div>
        <div class="shape-two"><img src="assets/img/inner-banner/banner-shape2.png" alt="Images"></div>
        <div class="shape-three"><img src="assets/img/inner-banner/banner-shape3.png" alt="Images"></div>
        <div class="inner-banner-dots"><img src="assets/img/shape/dots-shape.png" alt="Images"></div>
    </div>
</div>

<div class="terms-conditions-area pt-50 pb-70">
    <div class="container">
        <div class="single-content">
            <h3>Kalite Politikamız</h3>
            <p>Kalite Politikamız
                Devex Medya olarak, faaliyet gösterdiğimiz Bilişim Teknolojileri süreçlerinde, kalite, süreklilik ve kusursuz çalışmanın öneminin bilincindeyiz. Bu amaçla ISO 9001:2015 doğrultusunda Kalite Yönetim Sistemi Politikamız;

      <br>        • Gelişen ve güncel teknolojileri kullanmak ve sektördeki gelişmeleri yakından takip ederek ürün/hizmet kalitesini sürekli yükseltmek ve yasal süreçler doğrultusunda müşteri beklentilerini aşan ürün/hizmet geliştirmek,
      <br>        • En uygun, en doğru ve en ekonomik çözümleri en hızlı biçimde üreterek daha sonra ortaya çıkabilecek uygunsuzlukların önüne geçmek,
      <br>        • Müşterilerimizin ihtiyaç ve beklentilerini en üst seviyede karşılayan bir anlayış içinde güvenilir firma olmak,
      <br>        • Politikamız doğrultusunda kalite hedeflerimize ulaşmak için kalite anlayışımızın sürekliliğini sağlayarak içinde bulunduğumuz topluma ve çevreye saygılı, örnek bir firma olmak, çalışanlarımız ile hedeflerimize ulaşmak için gerekli tüm eğitimleri almak, uygun çalışma ortamını sağlamak ve bunu teknoloji ile destekleyerek bir aile olmak,
      <br>        • Deneyim ve birikimimizi daha da arttırarak ülke ekonomisine verdiğimiz hizmet kalitesi ile katkıda bulunmak,
      <br>        • Süreç ve yönetimde sistem yaklaşımı ile maksimum iletişim ve verimliliği sağlamak,
      <br>        • Verimlilik esasına dayalı olarak, sürekli iyileştirmeyi sürdürülebilir hale getirmek,
                için yönetim olarak Kalite Yönetim Sistemimizin sürekliliğini sağlamayı taahhüt ederiz.</p>
        </div>

    </div>
</div>


<app-footer-style-one></app-footer-style-one>
