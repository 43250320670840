<footer class="footer-area footer-bg pt-100 pb-70">
    <div class="container">
        <div class="footer-top">
            <div class="row align-items-center">
                <div class="col-lg-6 col-sm-6">
                    <div class="footer-img">
                        <img src="assets/img/logo/logo1.png" alt="Images" width="220">
                    </div>
                </div>

                <div class="col-lg-6 col-sm-6">
                    <div class="footer-social-icon">
                        <ul class="social-link">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-pinterest-alt'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="footer-midal pt-45 pb-70">
            <div class="row">
                <div class="col-lg-3 col-sm-5">
                    <div class="footer-widget">
                        <h3>Hizmetlerimiz</h3>

                        <ul class="footer-list">
                            <li><a routerLink="/seo-optimizasyonu">Seo Optimizasyonu</a></li>
                            <li><a routerLink="/web-tasarim">Web Tasarım</a></li>
                            <li><a routerLink="/web-programlama">Web Programlama</a></li>
                            <li><a routerLink="/mobil-programlama">Mobil Programlama</a></li>
                            <li><a routerLink="/yazilim-gelistirme">Yazılım Geliştirme</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-2 col-sm-7">
                    <div class="footer-widget">
                        <h3>Kurumsal</h3>

                        <ul class="footer-list">
                            <li><a routerLink="/hakkimizda">Hakkımızda</a></li>
                            <li><a routerLink="/hizmetlerimiz">Hizmetlerimiz</a></li>
                            <li><a routerLink="/kalite-politikamiz">Kalite Politikamız</a></li>
                            <li><a routerLink="/cozum-ortaklarimiz">Çözüm Ortaklarımız</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-5">
                    <div class="footer-widget pl-5">
                        <h3>Çözümlerimiz</h3>

                        <ul class="footer-list">
                            <li><a routerLink="/logo-tasarim">Logo Tasarım</a></li>
                            <li><a routerLink="/kurumsal-kimlik-tasarimi">Kurumsal Kimlik Tasarımı</a></li>
                            <li><a routerLink="/katalog-brosur-tasarimi">Katalog&Broşür Tasarımı</a></li>
                            <li><a routerLink="/marka-tescili">Marka Tescili</a></li>
                            <li><a routerLink="/baski-hizmetleri">Baskı Hizmetleri</a></li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-5">
                    <div class="footer-widget">
                        <h3>İletişim</h3>

                        <ul class="footer-list-two">
                            <li><i class='flaticon-telephone'></i><a href="tel:+905309462500">+90 530 25 00</a></li>
                            <li><i class='flaticon-email-1'></i><a href="mailto:info@devexmedya.com">info@devexmedya.com</a></li>
                            <li><i class='flaticon-place'></i>Gabralı İş Merkezi Kat:3 No:304<br>Çerkezköy/Tekirdağ</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <div class="copy-right-area">
            <div class="copy-right-text text-center">
                <p>Copyright @2020 Devex Medya. Tüm Hakları Saklıdır. <a href="https://devexmedya.com/" target="_blank">Devex Medya</a> </p>
            </div>
        </div>
    </div>
</footer>
